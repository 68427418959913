$(document).ready(function () {
    const navigationDialog = document.querySelector('.navigation-dialog-js')
    const closeButton = document.querySelector('.close-button-js')
    const navButton = document.querySelector('.nav-button-js')


    closeButton.addEventListener('click', () => {
        navigationDialog.classList.remove('open')
    })

    navButton.addEventListener('click', () => {
        navigationDialog.classList.add('open')
    })
});
